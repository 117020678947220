body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* this is to override the default font in the rich text editor section of the new request modal */
.RichTextEditor__root___2QXK- {
  font-family: 'Lato', sans-serif !important;
  height: 20rem;
  overflow: scroll;
}

.pusher > .container {
  margin-top: 70px;
}

.site > .container {
  margin-top: 70px;
}

.TextEditorError {
  font-family: 'Lato', sans-serif !important;
  height: 20rem;
  overflow: scroll;
  background: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
}

.RichTextEditor__editor___1QqIU {
  font-size: 14px !important;
}

div.visible.transition.request-card {
  display: flex !important;
}

.ui.active.text.loader.modal-loader:after {
  border-color: #767676 transparent transparent;
}

/* .ui.definition.table tr td.definition, .ui.definition.table tr td:first-child:not(.ignored) {

} */
/* The below is for notification previews */

/* Centered text */
.androidNotificationContainer {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.androidNotification {
  cursor: pointer;
  /* height: 270px; */
  font-family: Roboto, Proxima-Nova, 'Proxima Nova', 'Open Sans', Arial,
    Helvetica, sans-serif;
  background-color: rgb(254, 254, 254);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  max-width: 500px;
  overflow-y: hidden;
  box-sizing: content-box;
  padding: 16px;
  border-radius: 2px;
  transition: height 0.3s ease-out 0s;
}

.androidHeaderText {
  display: flex;
  font-size: 12px;
  line-height: 15px;
}

.androidBody {
  margin-top: 8px;
}

.androidImage {
  display: flex;
  width: 100%;
  height: 200px;
  margin-top: 10px;
  background-color: rgb(254, 254, 254);
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.androidTitleText {
  color: rgb(83, 83, 83);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.1px;
  display: inline-block;
  width: 283px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0px;
  overflow: hidden;
}

.androidButtonGroup {
  background-color: rgb(238, 238, 238);
  margin: 16px -16px -16px;
  padding: 16px 8px;
  display: block;
}

.androidButton {
  color: rgb(99, 99, 99);
  line-height: 18.5px;
  font-size: 13px;
  padding: 8px;
}

.chromeText {
  color: rgb(99, 99, 99);
  margin-right: 4px;
}

.chromeLogo {
  width: 13.12px;
  height: 13.12px;
  fill: rgb(117, 117, 117);
  margin-right: 5.9px;
}

.chromeLogoForMac {
  width: 49.93px;
  height: 49.93px;
}

.windowsNotificationContainer {
  width: 100%;
  height: 600px;
  background-color: #000000;
  margin: 0 auto;
  position: relative;
}

.windowsNotification {
  font-family: 'Segoe UI', Roboto, Proxima-Nova, 'Proxima Nova', 'Open Sans',
    Arial, Helvetica, sans-serif;
  background-color: #232323;
  width: 405px;
  height: 405px;
  position: absolute;
  bottom: 66px;
  right: 25px;
}

.windowsImageContainer {
  height: 207px;
  overflow: hidden;
}

.windowsIconContainer {
  height: 55px;
  width: 55px;
  overflow: hidden;
  position: relative;
  top: 26px;
  left: 18px;
}

.windowsTextContainer {
  left: 95px;
  top: -18px;
  position: relative;
  color: rgb(167, 167, 167);
  max-width: 75%;
}

.windowsTitleText {
  color: #ffffff;
  font-size: 17px;
}

.windowsSubtitleText {
  font-size: 17px;
  line-height: 23px;
}

.windowsBlah {
  margin-top: 10px;
}

.windowsIcon {
  width: 55px;
  height: 55px;
}

.windowsImage {
  object-fit: contain;
}

.ui.image.windowsIcons {
  position: absolute;
  right: 35px;
  bottom: 12px;
}

.windowsBottomBar {
  height: 46px;
  background-color: #1d1d1d;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.windowsButtonGroup {
  display: flex;
  padding: 0px 2em 2.9375em;
  margin: -3px;
}

.windowsButton {
  width: 30%;
  height: 100%;
  text-align: center;
  background-color: rgb(67, 67, 67);
  color: rgb(255, 255, 255);
  font-size: 125%;
  cursor: pointer;
  margin: 3px;
  padding: 10px 0px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(67, 67, 67);
  border-image: initial;
}

.macNotificationContainer {
  display: inline-grid;
  grid-template-columns: 13.89fr 66.73fr 19.38fr;
  font-family: Roboto, Proxima-Nova, 'Proxima Nova', 'Open Sans', Arial,
    Helvetica, sans-serif;
  max-width: 100%;
  max-height: 106px;
  background-color: rgb(240, 240, 240);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 13px 1px,
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  position: absolute;
  top: 60px;
  right: 0.25%;
  border-radius: 8px;
}

.macChromeLogoContainer {
  align-self: center;
  justify-self: center;
  padding: 26.3% 7.73% 26.3% 17.02%;
}

.macTopBarContainer {
  width: 100%;
  height: 600px;
  margin: 0 auto;
  position: relative;
}

.macTextContainer {
  align-self: center;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 11px;
  padding: 0px 4.44%;
  line-height: 25px;
}

.macTitleText {
  width: 100%;
  font-weight: 500;
  color: rgb(77, 77, 77);
  font-size: 1.5em;
  letter-spacing: 0.5px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0px;
  overflow: hidden;
}

.macOriginText {
  width: 100%;
  font-weight: 500;
  color: rgb(77, 77, 77);
  font-size: 18.72px;
  letter-spacing: 0.4px;
  margin: 0px;
}

.macSubtitleText {
  width: 100%;
  font-weight: 400;
  color: rgb(77, 77, 77);
  font-size: 1.4em;
  letter-spacing: 0.5px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0px;
  overflow: hidden;
}

.macIconContainer {
  align-self: center;
  justify-self: center;
  height: 100%;
  padding: 12.44%;
}

.macIcon {
  max-width: 100%;
  max-height: 100%;
}
