$chat-window-bg-color: #f3f2f2;
$sent-msg-color: hsla(226, 53%, 52%, 0.5);
$received-msg-color: #fffefd;
$shadow-color: #e7e6e6;
$author-color: #16233a;
$gray06: #abb0b8;
$icon-shadow: 1px 1px 4px 2px $gray06;

i.circular.inverted.icon.messenger-icon {
  position: fixed;
  right: 20px;
  bottom: 20px;
  box-shadow: var(--icon-shadow);
  opacity: 0.9;
  cursor: pointer;

  &:hover {
    opacity: 1;
    bottom: 22px;
  }
}

.ui.segment.chat-window {
  position: fixed;
  right: 10px;
  bottom: 0;
  height: 500px;
  width: 400px;
  z-index: 801;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 1em;
  padding-left: 1em;
  padding-bottom: 0;
  background-color: $chat-window-bg-color;
  display: flex !important;
  flex-flow: column;

  .scroll-arrow {
    position: absolute;
    bottom: 145px;
    right: 25px;
  }

  .chat-search {
    margin-bottom: 1em;

    input {
      border-radius: 500rem;
    }
  }

  .chat-list {
    overflow-y: scroll;
    margin-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
    box-shadow: 0px -2px 1px -1px $shadow-color;
    height: 100%;

    .feature {
      position: relative;
      background-color: $chat-window-bg-color;
      z-index: 14000002;

      &.jdlt-transition-up {
        animation: slideup 2s ease-out;
      }

      .start-chat {
        margin-top: 0.5em;
        font-size: 1em;
        font-weight: lighter;
        font-style: italic;
      }

      div {
        &.chat {
          margin-bottom: 1em;
          cursor: pointer;
          background-color: $chat-window-bg-color;
          box-shadow: 0px 2px 1px -1px $shadow-color;
          position: relative;

          &.jdlt-transition-up {
            animation: slideup 0.7s ease-out;
          }

          &.jdlt-transition-down {
            animation: slidedown 0.7s ease-out;
          }

          &.unread {
            font-weight: 700;
          }

          &:hover {
            background-color: rgba(256, 256, 256, 0.6);
            box-shadow: -2px 2px 10px -1px $shadow-color;
          }

          .column {
            .avatar-1 {
              position: absolute;
              left: 20;
              top: 25;
              z-index: 801;
              border: 1px solid $chat-window-bg-color;
            }

            .avatar-2 {
              position: absolute;
              top: 35px;
              left: 30px;
              z-index: 801;
              border: 1px solid $chat-window-bg-color;
            }

            .grid {
              .last-message-timestamp {
                padding-top: 0px;

                p.most-recent-message {
                  font-weight: lighter;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                  max-height: 20px;
                }
              }
              .chat-participant-name {
                padding-bottom: 0px;

                .button.archive-button {
                  transition: 0.5s ease;

                  &:hover {
                    color: #d01919 !important;
                    border-color: #d01919 !important;
                    box-shadow: 0 0 0 1px#d01919 inset, 0 0 0 0 #d01919 inset;
                  }
                }
              }

              .row {
                .chat-name {
                  margin-bottom: 0.5em;
                  font-size: 1.1em;

                  .unread-label {
                    position: absolute;
                    top: -6px;
                    transform: scale(0.9);
                  }
                }
                .timestamp {
                  font-weight: lighter;
                  text-align: right;
                  font-size: 0.9em;
                  &.unread {
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }
      }

      .feature-id-container {
        margin-bottom: 1.5em;
        margin-top: 0.5em;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: inline-flex;

        .feature-id {
          font-weight: 700;
          font-size: 1.25em;
          margin-right: 1em;
        }

        .feature-name {
          color: initial;
          font-weight: 700;
        }

        .scroll-container {
          overflow: hidden;
          display: block;
          height: 30px;
          line-height: 30px;
          font-size: 1.25em;
          width: 265px;
          position: relative;
          margin-left: 0.5em;

          .feature-name.scroll {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: block;
            color: initial;
            font-weight: 700;
          }

          &:hover {
            .feature-name.scroll {
              position: absolute;
              -webkit-animation-name: scrolltitletext;
              -webkit-animation-duration: 5s;
              -webkit-animation-timing-function: linear;
              animation-name: scrolltitletext;
              animation-duration: 5s;
              animation-timing-function: linear;
            }
          }
        }
      }
    }
  }
  .chat-window-heading {
    background-color: $chat-window-bg-color;
    .chat-window-heading-container {
      .column.feature-and-user-names {
        padding-left: 0;
      }

      .heading-text {
        .chat-feature-name {
          span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: block;
          }
          &:hover {
            span.scroll {
              position: absolute;
              -webkit-animation-name: scrolltext;
              -webkit-animation-duration: 5s;
              -webkit-animation-timing-function: linear;
              animation-name: scrolltext;
              animation-duration: 5s;
              animation-timing-function: linear;
            }
          }
        }
      }
      .row {
        &.heading-text {
          padding-bottom: 0;
          padding-top: 0;
          .chat-feature-name {
            overflow: hidden;
            display: block;
            height: 30px;
            line-height: 30px;
            font-size: 1.25em;
            width: 230px;
            position: relative;
          }
        }
        &.user-names {
          padding-top: 0.5em;
          p {
            font-size: 1rem;
            color: $author-color;
            font-weight: lighter;
            margin-top: 0.25em;

            &.typing-members {
              font-style: italic;
            }
          }
        }
        &.icons-row {
          padding-bottom: 0.5em;

          .reply.icon.back-to-list-icon {
            margin-left: 0.5em;
          }
          .delete.icon {
            padding-right: 1em;
          }
        }
      }
    }
  }
  .chat-messages {
    overflow-y: scroll;
    overflow-x: hidden;
    flex: auto;
    display: flex;
    flex-flow: column;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-bottom: 1em;
    margin-bottom: 0;
    margin-top: 0;
    position: relative;

    &.loaded {
      box-shadow: 0px -2px 1px -1px $shadow-color;
    }

    .chat-message {
      border-radius: 4px;
      padding: 0.75em;
      padding-bottom: 0.25em;
      margin-top: 0.25em;
      margin-bottom: 0.25em;
      max-width: 95%;
      word-wrap: break-word;
      position: relative;

      .chat-message-author {
        font-size: 0.9em;
        color: $author-color;
        font-weight: lighter;
        margin-bottom: 0.5em;
      }

      .chat-message-body {
        font-size: 1.1em;
        margin-bottom: 0;
        opacity: 1;
        white-space: pre-line;
        * {
          font-size: 1.1em;
          margin-bottom: 0;
          opacity: 1;
          white-space: pre-line;
        }
      }

      p.timestamp {
        text-align: right;
        font-size: 0.8em;
        font-weight: lighter;
      }
    }
    .sent-chat-message {
      align-self: flex-end;
      background-color: $sent-msg-color;
      margin-right: 0.7em;
      left: 200px;
      transition: left 0.4s linear, opacity 0.7s linear;

      + .received-chat-message {
        margin-top: 1em;
      }

      &.loaded {
        left: 0;
        opacity: 1;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 60%;
        width: 0;
        height: 0;
        border: 0.531em solid transparent;
        border-left-color: $sent-msg-color;
        border-right: 0;
        border-bottom: 0;
        margin-top: -0.266em;
        margin-right: -0.5em;
      }
    }
    .received-chat-message {
      align-self: flex-start;
      background-color: $received-msg-color;
      margin-left: 0.5em;
      position: relative;
      right: 200px;
      transition: right 0.4s linear, opacity 0.7s linear;
      opacity: 0;

      + .sent-chat-message {
        margin-top: 1em;
      }

      &.loaded {
        right: 0;
        opacity: 1;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 60%;
        width: 0;
        height: 0;
        border: 0.625em solid transparent;
        border-right-color: $received-msg-color;
        border-left: 0;
        border-bottom: 0;
        margin-top: -0.312em;
        margin-left: -0.625em;
      }
    }
  }
  .chat-window-controls {
    padding: 1em 0 0.5em 0;
    height: auto;
    box-shadow: 0px -2px 1px -1px $shadow-color;
    margin-top: 0.5em;

    .chat-button.ui.button {
      margin-left: 1rem;
    }

    .icon-bar {
      display: flex;
      justify-content: flex-end;
      padding-top: 0.5rem;

      .send-icon {
        font-size: 1.3em;
      }

      .character-limit {
        font-weight: lighter;
        margin-right: 0.5em;

        &.exceeded {
          color: red;
        }
      }
    }

    .chat-input {
      resize: none;
      width: 100%;
      max-width: 100%;
      border-radius: 4px;
      border: 1px solid $shadow-color;
      padding: 0.5em;

      &.exceeded {
        outline: 1px solid red;
      }
    }
  }
}

//mobile styling
.ui.segment.chat-window.mobile {
  height: 100vh;
  height: var(--fullViewPortHeight, 100vh);
  width: 100vw;
  right: 0;
  .chat-window-controls {
    .chat-input {
      font-size: 16px;
    }
  }
}

@media (max-width: 767px) {
  .ui.segment.chat-window
    > .chat-list
    > .feature
    > .chat
    > .column
    > .grid
    > .chat-participant-name
    .button.archive-button {
    color: #d01919 !important;
    border-color: #d01919 !important;
    box-shadow: 0 0 0 1px#d01919 inset, 0 0 0 0 #d01919 inset;
  }
}

// using a media query for tablet and above to keep title (in chat list) in hover state until cursor is moved
@media (min-width: 992px) {
  .ui.segment.chat-window
    > .chat-list
    > .feature
    > .feature-id-container
    .scroll-container:hover
    span {
    transform: translateX(calc(250px - 100%));
    animation-name: scrolltitletext;
    animation-duration: 5s;
    animation-timing-function: linear;
  }
}

// using a media query for tablet and above to keep title (in chat) in hover state until cursor is moved
@media (min-width: 992px) {
  .chat-window-heading .heading-text .chat-feature-name:hover span.scroll {
    transform: translateX(calc(210px - 100%));
    animation-name: scrolltext;
    animation-duration: 5s;
    animation-timing-function: linear;
  }
}

//animation for feature in chat list when new message is received
@keyframes slideup {
  from {
    top: 77.5px;
    z-index: 14000001;
    opacity: 0;
  }

  19% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }

  99% {
    z-index: 14000001;
  }
  to {
    top: 0;
    z-index: 14000002;
  }
}

//animation for feature in chat list when new message is received when there are multiple chats in a feature
@keyframes slidedown {
  from {
    bottom: 77.5px;
    z-index: 14000002;
  }

  99% {
    z-index: 14000002;
  }
  to {
    bottom: 0;
    z-index: 14000001;
  }
}

// animation for feature title in chat list to scroll on hover
@keyframes scrolltitletext {
  0% {
    -webkit-transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(calc(250px - 100%));
  }

  100% {
    -webkit-transform: translateX(calc(250px - 100%));
  }
}

// animation for feature title in chat to scroll on hover
@keyframes scrolltext {
  0% {
    -webkit-transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(calc(210px - 100%));
  }

  100% {
    -webkit-transform: translateX(calc(210px - 100%));
  }
}
